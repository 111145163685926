import React, { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "react-three-fiber";
import { Vector3 } from "three";
import { gsap } from "gsap";
import Menu from "../components/Menu";
import Iframe from "../components/Iframe";
import Model from "../components/Model";
import EnvironmentSetting from "../components/EnvironmentSetting";

const LandingPage = ({
  setWelcomeVisible,
  setKeyPad,
  audioEnabled,
  setAudioEnabled,
}) => {
  //MODEL REF
  const LayoutUrl = "Assets/Models/Landing/Layout-v4-final.glb";
  const VisionUrl = "Assets/Models/Vision/Vision.glb";
  const TabletUrl = "Assets/Models/Tablet/tablet.glb";

  const LayoutModel = useRef(null);
  const VisionModel = useRef(null);
  const TabletModel = useRef(null);
  const WindAudio = useRef(new Audio("Assets/Audio/wind.mp3"));
  const GlowLightAudio = useRef(new Audio("Assets/Audio/glowlight.mp3"));
  const MenuAudioEntry = useRef(new Audio("Assets/Audio/Entry.mp3"));
  const MenuAudioExit = useRef(new Audio("Assets/Audio/Exit.mp3"));
  const MoveAudio = useRef(new Audio("Assets/Audio/Move.mp3"));
  const DamaruAudio = useRef(new Audio("Assets/Audio/damaru_loop.mp3"));
  const TanpuraAudio = useRef(new Audio("Assets/Audio/tanpura_loop.mp3"));

  //ENABLERS

  //ENABLERS
  const [menu, setMenu] = useState(false);
  const [goggles, setGoggles] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [duality, setDuality] = useState(false);
  const [keyListener, setKeyListener] = useState(true);
  const [cursorMove, setcursorMove] = useState(true);

  //INFORMATION-STATE
  const [cursor, setCursor] = useState({ x: 0, y: 0 });
  const [currentScene, setCurrentScene] = useState("Main");
  const [bloomSettings, setBloomSettings] = useState({
    intensity: 1.6,
    threshold: 0.1,
    level: 6.3,
  });
  const [glowlightSettings, setGlowlightSettings] = useState({
    intensity: 5,
    distance: 2.2,
    position: { x: -1.08, y: -0.58, z: -6.83 },
    color: "#fcea8b",
  });
  const [fogSettings, setFogSettings] = useState({
    near: 2.7,
    far: 14.4,
  });
  const [tabletsrc, setTabletsrc] = useState("Assets/Text/intellect.html");
  const { camera } = useThree();

  //USE-EFFECT with Event Listener and toggle functions for Goggles, Tablet and HeadLamp
  useEffect(() => {
    const updateGuide = (update, delay) => {
      gsap.to(".keypad", {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          setKeyPad(update);
        },
      });
      gsap.to(".keypad", { opacity: 1, duration: 1, delay: delay }, ">");
    };

    const toggleGoggles = () => {
      if (!keyListener || currentScene === "Main" || currentScene === "Vision")
        return;

      const GogglesOnAudio = new Audio("Assets/Audio/gogglesOn.mp3");
      const GogglesOffAudio = new Audio("Assets/Audio/gogglesOff.mp3");

      setKeyListener(false);
      let currentstate = !goggles;
      setGoggles((prevState) => !prevState);

      if (currentScene === "Conditioning") {
        if (currentstate) {
          GogglesOnAudio.play();
          updateGuide({ G: "Disable Goggles" }, 1);
          setBloomSettings({ intensity: 0.63, threshold: 0.1, level: 5 });
          LayoutModel.current.traverse((child) => {
            if (child.material && child.material.name === "Glow.002") {
              gsap.to(child.material.emissive, {
                r: 0.252,
                g: 0.186,
                b: 0.03,
                duration: 2,
                onComplete: () => {
                  setKeyListener(true);
                },
              });
              gsap.to(
                child.material,
                {
                  emissiveIntensity: 2,
                  duration: 2,
                },
                "<"
              );
            }
          });
        } else {
          GogglesOffAudio.play();
          setBloomSettings({ intensity: 1.6, threshold: 0.1, level: 6.3 });
          LayoutModel.current.traverse((child) => {
            if (child.material && child.material.name === "Glow.002") {
              child.material.emissive.r = 0;
              child.material.emissive.g = 0;
              child.material.emissive.b = 0;
              child.material.emissiveIntensity = 0;
            }
          });

          setKeyListener(true);
          updateGuide({ G: "Enable Goggles", T: "Enable Text", B: "Back" }, 0);
        }
      } else if (currentScene === "Intellect") {
        if (currentstate) {
          GogglesOnAudio.play();
          LayoutModel.current.traverse((child) => {
            if (child.material && child.material.name === "lambert24.001") {
              child.material.wireframe = true;
            } else if (
              child.material &&
              child.material.name === "lambert25.001"
            ) {
              child.material.wireframe = true;
            } else if (
              child.material &&
              child.material.name === "lambert26.001"
            ) {
              child.material.wireframe = true;
            } else if (
              child.material &&
              child.material.name === "lambert27.001"
            ) {
              child.material.wireframe = true;
            } else if (child.material && child.material.name === "material") {
              child.material.wireframe = true;
            }
          });
          setKeyListener(true);
          updateGuide({ G: "Disable Goggles" }, 0);
        } else {
          GogglesOffAudio.play();
          LayoutModel.current.traverse((child) => {
            if (child.material && child.material.name === "lambert24.001") {
              child.material.wireframe = false;
            } else if (
              child.material &&
              child.material.name === "lambert25.001"
            ) {
              child.material.wireframe = false;
            } else if (
              child.material &&
              child.material.name === "lambert26.001"
            ) {
              child.material.wireframe = false;
            } else if (
              child.material &&
              child.material.name === "lambert27.001"
            ) {
              child.material.wireframe = false;
            } else if (child.material && child.material.name === "material") {
              child.material.wireframe = false;
            }
          });

          setKeyListener(true);
          updateGuide({ G: "Enable Goggles", T: "Enable Text", B: "Back" }, 0);
        }
      } else if (currentScene === "Vision") {
        if (currentstate) {
          GogglesOnAudio.play();
          updateGuide({ G: "Disable Goggles" }, 0);
          setKeyListener(true);
        } else {
          GogglesOffAudio.play();
          updateGuide(
            {
              G: "Enable Goggles",
              T: "Enable Text",
              D: "Dance of Duality",
              B: "Back",
            },
            0
          );
          setKeyListener(true);
        }
      }
    };

    const toggleTablet = () => {
      if (!keyListener || currentScene === "Main") return;

      const TabletEntry = new Audio("Assets/Audio/TabletOn.mp3");
      const TabletExit = new Audio("Assets/Audio/TabletOff.mp3");

      setKeyListener(false);
      let currentstate = !tablet;
      setTablet((prevState) => !prevState);

      if (currentstate) {
        TabletEntry.play();
        if (currentScene === "Conditioning") {
          setTabletsrc("Assets/Text/conditioning.html");
          TabletModel.current.rotation.set(-0.19, -1.85, 0.19);
          updateGuide({ T: "Disable Text" }, 1);
          gsap.fromTo(
            TabletModel.current.position,
            { x: 3.15, y: -3, z: -9 },
            {
              x: 4.03,
              y: -1.64,
              z: -9.64,
              duration: 2,
              onComplete: () => {
                setKeyListener(true);
              },
            }
          );
        } else if (currentScene === "Intellect") {
          setTabletsrc("Assets/Text/intellect.html");
          updateGuide({ T: "Disable Text" }, 1);
          TabletModel.current.rotation.set(0.09, 0.05, 0);
          TabletModel.current.scale.set(0.19, 0.19, 0.19);
          gsap.fromTo(
            TabletModel.current.position,
            { x: -2.17, y: 0, z: -7 },
            {
              x: -2.2,
              y: -2.1,
              z: -8.63,
              duration: 2,
              onComplete: () => {
                setKeyListener(true);
              },
            }
          );
        } else if (currentScene === "Vision") {
          //Show Textual representation
          document.querySelector(".overlay-only").style.display = "flex";
          gsap.to(".overlay-only", {
            opacity: 1,
            duration: 1,
            backgroundColor: "#0000001f",
            height: "63%",
          });

          updateGuide({ T: "Disable Text" }, 1);
          gsap.to(".for-vision", {
            opacity: 1,
            duration: 2,
            onComplete: () => {
              setKeyListener(true);
            },
          });
        } else if (currentScene === "Contact") {
          setTabletsrc("Assets/Text/contact.html");
          TabletModel.current.rotation.set(-0.29, 0, 0);
          updateGuide({ B: "Back" }, 1);
          gsap.fromTo(
            TabletModel.current.position,
            { x: 0.15, y: -3, z: 3 },
            {
              x: 0,
              y: -0.34,
              z: -1.86,
              duration: 2,
              onComplete: () => {
                setKeyListener(true);
              },
            }
          );
        }
      } else {
        TabletExit.play();
        if (currentScene === "Vision") {
          updateGuide(
            {
              T: "Enable Text",
              D: "Dance of Duality",
              B: "Back",
            },
            0
          );
          document.querySelector(".overlay-only").style.display = "none";
          document.querySelector(".overlay-only").style.opacity = 0;
          document.querySelector(".overlay-only").style.backgroundColor =
            "#000";
          document.querySelector(".overlay-only").style.height = "100%";
          document.querySelector(".for-vision").style.opacity = 0;
          setKeyListener(true);
        } else if (currentScene === "Contact") {
          updateGuide(
            {
              M: "Guide",
            },
            1
          );
          gsap.to(camera.position, { x: 0.25, y: -0.36, z: 0, duration: 3 });
          gsap.to(camera.rotation, {
            x: 0,
            y: 0,
            z: 0,
            duration: 3,
            onComplete: () => {
              setKeyListener(true);
              setcursorMove(true);
              setCurrentScene("Main");
            },
          });
          gsap.to(
            TabletModel.current.position,
            {
              x: 0,
              y: -6,
              z: 5,
              duration: 2,
            },
            "<"
          );
        } else {
          updateGuide(
            {
              G: "Enable Goggles",
              T: "Enable Text",
              B: "Back",
            },
            1
          );
          gsap.to(TabletModel.current.position, {
            x: 0,
            y: -6,
            z: 5,
            duration: 2,
            onComplete: () => {
              TabletModel.current.scale.set(0.56, 0.56, 0.56);
              setKeyListener(true);
            },
          });
        }
      }
    };

    const toggleDuality = () => {
      if (
        !keyListener ||
        currentScene === "Main" ||
        currentScene === "Intellect" ||
        currentScene === "Conditioning"
      )
        return;

      setKeyListener(false);
      let currentstate = !duality;
      setDuality((prevState) => !prevState);

      if (currentstate) {
        DamaruAudio.current.play();
        DamaruAudio.current.loop = true;
        gsap.to(DamaruAudio.current, { volume: 0.9, duration: 2 });
        VisionModel.current.traverse((child) => {
          if (child.name === "Magic_Marble") {
            gsap.to(child.scale, { x: 4.5, y: 4.5, z: 4.5, duration: 2 });
          }
          if (child.material && child.material.name === "skydome") {
            gsap.to(child.material, {
              emissiveIntensity: 0.25,
              duration: 2,
              onComplete: () => {
                setKeyListener(true);
              },
            });
            gsap.to(
              child.material.emissive,
              { r: 0.13, g: 0, b: 0, duration: 2 },
              "<"
            );
          }
          if (child.material && child.material.name === "StatueMaterial") {
            gsap.to(child.material, { emissiveIntensity: 0.1, duration: 2 });
          }
        });
      } else {
        gsap.to(DamaruAudio.current, {
          volume: 0,
          duration: 2,
          onComplete: () => {
            DamaruAudio.current.pause();
          },
        });
        VisionModel.current.traverse((child) => {
          if (child.name === "Magic_Marble") {
            gsap.to(child.scale, { x: 1.596, y: 1.596, z: 1.596, duration: 2 });
          }
          if (child.material && child.material.name === "skydome") {
            gsap.to(child.material, {
              emissiveIntensity: 0.15,
              duration: 2,
              onComplete: () => {
                setKeyListener(true);
              },
            });
            gsap.to(
              child.material.emissive,
              { r: 1, g: 1, b: 1, duration: 2 },
              "<"
            );
          }
          if (child.material && child.material.name === "StatueMaterial") {
            gsap.to(child.material, { emissiveIntensity: 1, duration: 2 });
          }
        });
      }
    };

    const backToLandingPage = () => {
      if (!keyListener || currentScene === "Main") return;
      if (tablet || goggles) return;
      MoveAudio.current.play();
      setKeyListener(false);
      setCurrentScene("Main");
      if (currentScene === "Vision") {
        gsap.to(TanpuraAudio.current, {
          volume: 0,
          duration: 1,
          onComplete: () => {
            TanpuraAudio.current.pause();
          },
        });
        gsap.to(
          DamaruAudio.current,
          {
            volume: 0,
            duration: 1,
            onComplete: () => {
              DamaruAudio.current.pause();
            },
          },
          "<"
        );
        updateGuide(
          {
            M: "Guide",
          },
          1
        );
        gsap.to(".overlay-only", {
          opacity: 1,
          duration: 1,
          display: "block",
          onComplete: () => {
            VisionModel.current.position.set(0, -85, -90);
            VisionModel.current.rotation.set(-0.15, -0.14, 0);
            VisionModel.current.visible = false;
            LayoutModel.current.visible = true;
            setGlowlightSettings({
              intensity: 5,
              distance: 2.46,
              position: { x: -1.08, y: -0.58, z: -6.83 },
              color: "#fcea8b",
            });
            setBloomSettings({ intensity: 1.6, threshold: 0.1, level: 6.3 });
            setFogSettings({ near: 2.7, far: 14.4 });
            setcursorMove(true);
          },
        });
        gsap.to(
          ".overlay-only",
          {
            opacity: 0,
            duration: 1,
            display: "none",
            onComplete: () => {
              setKeyListener(true);
              setAudioEnabled(true);
            },
          },
          ">"
        );
      } else {
        updateGuide(
          {
            M: "Guide",
          },
          2
        );
        setGlowlightSettings({
          intensity: 5,
          distance: 2.2,
          position: { x: -1.08, y: -0.58, z: -6.83 },
          color: "#fcea8b",
        });
        setBloomSettings({ intensity: 1.6, threshold: 0.1, level: 6.3 });
        gsap.to(camera.position, { x: 0.25, y: -0.36, z: 0, duration: 3 });
        gsap.to(camera.rotation, {
          x: 0,
          y: 0,
          z: 0,
          duration: 3,
          onComplete: () => {
            setcursorMove(true);
            setKeyListener(true);
          },
        });
      }
    };

    const handleKeyUp = (event) => {
      if (event.code === "KeyM") {
        if (currentScene === "Main") {
          setMenu((prevState) => {
            if (prevState) MenuAudioExit.current.play();
            else MenuAudioEntry.current.play();
            return !prevState;
          });
        } else return;
      } else if (event.code === "KeyG") {
        toggleGoggles();
      } else if (event.code === "KeyT") {
        toggleTablet();
      } else if (event.code === "KeyB") {
        if (currentScene === "Contact") {
          toggleTablet();
        } else {
          backToLandingPage();
        }
      } else if (event.code === "KeyD") {
        toggleDuality();
      } else {
        return;
      }
    };
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    keyListener,
    currentScene,
    goggles,
    tablet,
    duality,
    camera,
    setKeyPad,
    setAudioEnabled,
  ]);

  const updateGuide = (update, delay) => {
    gsap.to(".keypad", {
      opacity: 0,
      duration: 1,
      onComplete: () => {
        setKeyPad(update);
      },
    });
    gsap.to(".keypad", { opacity: 1, duration: 1, delay: delay }, ">");
  };

  //HANDLING MENU SELECTION
  const selectionHandler = (event) => {
    MoveAudio.current.play();
    setcursorMove(false);
    setWelcomeVisible(false);
    switch (event.eventObject.name) {
      case "CONDITIONING":
        updateGuide(
          {
            G: "Enable Goggles",
            T: "Enable Text",
            B: "Back",
          },
          2
        );
        gsap.to(camera.position, { x: 2.37, y: -1.75, z: -10.12, duration: 2 });
        gsap.to(camera.rotation, {
          x: -0.19,
          y: -1.85,
          z: 0.19,
          duration: 3,
          onComplete: () => {
            setMenu(false);
            setCurrentScene("Conditioning");
          },
        });
        break;

      case "INTELLECT":
        updateGuide(
          {
            G: "Enable Goggles",
            T: "Enable Text",
            B: "Back",
          },
          1
        );
        gsap.to(camera.position, {
          x: -2.17,
          y: -2.15,
          z: -8.06,
          duration: 2,
          onComplete: () => {
            setMenu(false);
            setCurrentScene("Intellect");
            setGlowlightSettings({
              intensity: 2.35,
              distance: 4.5,
              position: { x: -1.08, y: -0.58, z: -6.83 },
              color: "#fcea8b",
            });
          },
        });
        gsap.to(camera.rotation, {
          x: 0.09,
          y: 0.05,
          z: 0,
          duration: 3,
        });

        break;

      case "CONTACT":
        gsap.to(camera.position, {
          x: 0,
          y: 0,
          z: 0,
          duration: 1,
          onComplete: () => {
            setCurrentScene("Contact");
          },
        });
        gsap.to(camera.rotation, {
          x: -0.19,
          y: 0,
          z: 0,
          duration: 2,
          onComplete: () => {
            setMenu(false);
            window.dispatchEvent(new KeyboardEvent("keyup", { code: "KeyT" }));
          },
        });
        break;

      case "VISION":
        setMenu(false);
        updateGuide(
          {
            T: "Enable Text",
            D: "Dance of Duality",
            B: "Back",
          },
          6
        );
        setAudioEnabled(false);
        const headChangeOver = () => {
          LayoutModel.current.visible = false;
          VisionModel.current.visible = true;
          setGlowlightSettings({
            intensity: 0.36,
            distance: 70,
            position: { x: -1, y: 1, z: 0 },
            color: "#fcea8b",
          });
          setBloomSettings({ intensity: 2.7, threshold: 0.1, level: 6 });
          setFogSettings({ near: 2.7, far: 2.5 });

          camera.position.set(0, 0, 0);
          gsap.to(".overlay-only", {
            opacity: 0,
            duration: 1,
            display: "none",
          });
          gsap.to(
            VisionModel.current.rotation,
            { x: -0.15, y: 0.76, z: 0, duration: 1 },
            "<"
          );
          gsap.to(
            VisionModel.current.position,
            { x: -32.02, y: -79.95, z: -50, duration: 1 },
            "<"
          );
          gsap.to(
            VisionModel.current.position,
            {
              x: -31.26,
              y: -80.75,
              z: -27.32,
              duration: 1,
              delay: 0.8,
              onComplete: () => {
                setCurrentScene("Vision");
                TanpuraAudio.current.loop = true;
                TanpuraAudio.current.volume = 0;
                TanpuraAudio.current.play();
                gsap.to(TanpuraAudio.current, {
                  volume: 0.9,
                  duration: 2,
                  delay: 1,
                });
                setGlowlightSettings({
                  intensity: 5,
                  distance: 2.2,
                  position: { x: -1.08, y: -0.58, z: -6.83 },
                  color: "#fcea8b",
                });
              },
            },
            "<"
          );
        };
        gsap.to(camera.position, { x: 0.245, y: -0.6, z: -6.56, duration: 2 });
        gsap.to(
          camera.rotation,
          {
            x: -0.18,
            y: 0,
            z: 0,
            duration: 1,
          },
          "<"
        );
        gsap.to(
          ".overlay-only",
          {
            display: "block",
            opacity: 1,
            duration: 1,
            onComplete: headChangeOver,
          },
          ">"
        );
        break;
      default:
        break;
    }
  };

  //USE-EFFECT FOR AUDIO

  useEffect(() => {
    if (audioEnabled) {
      WindAudio.current.volume = 0;
      WindAudio.current.loop = true;
      GlowLightAudio.current.volume = 0;
      GlowLightAudio.current.loop = true;
      WindAudio.current.play();
      GlowLightAudio.current.play();
      gsap.to(WindAudio.current, { volume: 0.54, duration: 2, delay: 4 });
      gsap.to(
        GlowLightAudio.current,
        { volume: 0.18, duration: 2, delay: 4 },
        "<"
      );

      if (DamaruAudio) {
        DamaruAudio.current.addEventListener("timeupdate", function () {
          var buffer = 0.333;
          if (this.currentTime > this.duration - buffer) {
            this.currentTime = 0;
            this.play();
          }
        });
      }
      if (TanpuraAudio) {
        TanpuraAudio.current.addEventListener("timeupdate", function () {
          var buffer = 0.333;
          if (this.currentTime > this.duration - buffer) {
            this.currentTime = 0;
            this.play();
          }
        });
      }
    } else {
      WindAudio.current.pause();
      gsap.to(WindAudio.current, {
        volume: 0,
        duration: 1,
        onComplete: () => {
          WindAudio.current.pause();
        },
      });
      gsap.to(GlowLightAudio.current, { volume: 0, duration: 1 }, "<");
    }
  }, [audioEnabled]);

  //USE-EFFECT FOR CURSOR MOVEMENT
  useEffect(() => {
    let cursorUpdated = {
      x: 0,
      y: 0,
    };
    window.addEventListener("mousemove", (event) => {
      cursorUpdated.x = event.clientX / window.innerWidth - 0.5;
      cursorUpdated.y = event.clientY / window.innerHeight - 0.5;
      setCursor(cursorUpdated);
    });
    if (LayoutModel) {
      LayoutModel.current.traverse((child) => {
        if (child.type === "Mesh" && /Lava/.test(child.name)) {
          child.receiveShadow = true;
        }
        if (child.type === "Mesh" && /Tripod/.test(child.name)) {
          child.castShadow = true;
        }
      });
    }
  }, []);

  useFrame((state, delta) => {
    if (cursorMove) {
      state.camera.position.lerp(
        new Vector3(cursor.x * 3.6, 0, cursor.y * 3.6),
        0.02
      );
      state.camera.lookAt(0.25, -0.36, -7.5);
    }
  });

  return (
    <>
      <Model
        url={LayoutUrl}
        position={{ x: 12.87, y: -3.69, z: -10.44 }}
        rotation={{ x: -0.21, y: -0.47, z: 0.03 }}
        scale={1}
        name="Landing-Model"
        ref={LayoutModel}
        animation={false}
      />
      <Model
        url={VisionUrl}
        position={{ x: 0, y: -85, z: -90 }}
        rotation={{ x: -0.15, y: -0.14, z: 0 }}
        scale={1}
        name="Vision"
        ref={VisionModel}
        animation={true}
        hidden={true}
      />
      <Menu menu={menu} selectionHandler={selectionHandler} />
      <EnvironmentSetting
        bloomSettings={bloomSettings}
        glowlightSettings={glowlightSettings}
        goggles={goggles}
        fogSettings={fogSettings}
      />
      <Model
        url={TabletUrl}
        position={{ x: 0, y: 0, z: 5 }}
        rotation={{ x: 0, y: 0, z: 0 }}
        scale={0.56}
        name="Tablet"
        ref={TabletModel}
        Children={<Iframe src={tabletsrc} />}
        animation={false}
      />
    </>
  );
};

export default LandingPage;
