import React from "react";
import { Environment, Lightformer, Stars } from "@react-three/drei";
import {
  Bloom,
  EffectComposer,
  Glitch,
  Vignette,
  Noise,
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

const EnvironmentSetting = ({
  bloomSettings,
  glowlightSettings,
  goggles,
  fogSettings,
}) => {
  return (
    <>
      <Stars
        radius={100}
        depth={10}
        count={2700}
        factor={1.8}
        saturation={0.9}
        fade
        speed={1}
      />
      <pointLight
        position={[
          glowlightSettings.position.x,
          glowlightSettings.position.y,
          glowlightSettings.position.z,
        ]}
        color={glowlightSettings.color}
        intensity={glowlightSettings.intensity}
        distance={glowlightSettings.distance}
        castShadow
        shadow-mapSize={[512, 512]}
        shadow-camera-near={0.2}
        shadow-camera-far={500}
      />
      <Environment>
        <Lightformer
          form="circle"
          intensity={1.8}
          position={[-3.6, 7.2, -0.9]}
          color="#e6efff"
          scale={2.7}
        />
      </Environment>
      <fog
        is="custom"
        attach="fog"
        color="#151617"
        near={fogSettings.near}
        far={fogSettings.far}
      />
      <EffectComposer multisampling={4}>
        <Bloom
          mipmapBlur
          intensity={bloomSettings.intensity}
          luminanceThreshold={bloomSettings.threshold}
          levels={bloomSettings.level}
        />

        {goggles && (
          <>
            <Glitch
              delay={[0.5, 24]}
              duration={[0.1, 0.3]}
              strength={[0.2, 0.3]}
            />
            <Vignette
              offset={0.3}
              darkness={0.9}
              blendFunction={BlendFunction.NORMAL}
            />
            <Noise blendFunction={BlendFunction.SOFT_LIGHT} />
          </>
        )}
      </EffectComposer>
    </>
  );
};

export default EnvironmentSetting;
